/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { StyledListItem, StyledOverViewLabel, StyledOverViewLink } from './NavigationStyles';
import { TrackingServiceContext, useInPageDispatch, useInPageNavContext } from '../../context';
import { getParentItemHref, getParentItemTitle, getTrackingLabelDisplay } from '../../utils/utils';
import { ActiveLinks } from '../../../types';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import { useTracking } from '../../hooks/use-tracking';

interface OverViewLabelProps {
  isSecondaryLevel?: boolean;
}

interface LabelProps extends OverViewLabelProps {
  isParentItem?: boolean;
}

const Link = React.memo(() => {
  const dispatch = useInPageDispatch();
  const { parentPageInpageNavigation, isLoadedFromUrl, subNavActive } = useInPageNavContext();
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);
  const propertyPath = 'parentPageInpageNavigation';
  if (!getParentItemHref(parentPageInpageNavigation)) {
    return <SecondLevelLabel isParentItem />;
  }

  const handleSelectedItem = useCallback(
    ({ id, index, text, href, target }: ActiveLinks) => {
      dispatch?.({
        type: INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK,
        payload: {
          sameLevelActiveLink: { id, index, text, href, target, overviewLinkSelected: true },
        },
      });
      // tracking for topnav parent link click
      tracking?.navigationItemClick({
        elementName: 'text link',
        label: text,
        clickID: '',
        targetURL: href || '',
        value: 'same level',
        pos: index.toString(),
        isSubNavigation: false,
        eventName: `inpage navigation - click on link`,
      });
    },
    [dispatch, tracking, subNavActive],
  );

  return (
    <StyledOverViewLink
      $hasParentElement
      $isSecondaryLevel={false}
      href={getParentItemHref(parentPageInpageNavigation)}
      className={!isLoadedFromUrl ? 'active-link' : ''}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id = '', fields } = parentPageInpageNavigation || {};
        const { subLevelParentLabel = '', internalLink } = fields || {};
        const path = internalLink?.path || '';

        handleSelectedItem({
          id,
          index: 0,
          text: subLevelParentLabel,
          href: path,
          target: '_self',
        });

        if (typeof window !== 'undefined' && path && isLoadedFromUrl) {
          window.location.href = path;
        }
      }}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation item">
        <UeElement type="text" property="subLevelParentLabel" label="Parent Page Item Label">
          {getParentItemTitle(parentPageInpageNavigation)}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  );
});

const SecondLevelLabel = React.memo(({ isParentItem, isSecondaryLevel }: LabelProps) => {
  const dispatch = useInPageDispatch();
  const {
    parentPageInpageNavigation,
    sameLevelActiveLink,
    topLevelActiveLink,
    secondLevelActiveLink,
    isLoadedFromUrl,
  } = useInPageNavContext();
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);
  const [activeLink, setActivelink] = useState<ActiveLinks>();
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    if (!isParentItem) {
      setActivelink(isSecondaryLevel ? topLevelActiveLink : secondLevelActiveLink);
    }
  }, [isParentItem]);

  useEffect(() => {
    const overviewLabel =
      activeLink?.subLevelChildLabel || activeLink?.text || topLevelActiveLink?.text || '';

    setLabel(
      activeLink?.parentLabel ||
        overviewLabel ||
        parentPageInpageNavigation?.fields?.subLevelParentLabel ||
        '',
    );
  }, [topLevelActiveLink, activeLink, parentPageInpageNavigation]);

  const handleSelectedItem = useCallback(
    ({ id, index, text, href, target }: ActiveLinks) => {
      dispatch?.({
        type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
        payload: {
          secondLevelActiveLink: { id, index, text, href, target, overviewLinkSelected: true },
        },
      });

      // tracking for subnav parent link click
      tracking?.navigationItemClick({
        elementName: 'text link',
        label: `${topLevelActiveLink?.subLevelChildLabel || sameLevelActiveLink?.subLevelChildLabel}${getTrackingLabelDisplay(secondLevelActiveLink, sameLevelActiveLink, topLevelActiveLink)}`,
        clickID: '',
        targetURL: href || '',
        value: `${index} sub level`,
        pos: `${index}-${index}`,
        isSubNavigation: true,
        eventName: `inpage navigation - click on link`,
      });
    },
    [dispatch],
  );

  const propertyPath = isSecondaryLevel
    ? `sameLevelInpageNavigation.${activeLink?.index}`
    : 'parentPageInpageNavigation';

  return sameLevelActiveLink?.subLevelChildLabel &&
    sameLevelActiveLink?.href &&
    !topLevelActiveLink &&
    !isParentItem ? (
    <StyledOverViewLink
      $isSecondaryLevel
      $isActive
      $subPageParent
      href={sameLevelActiveLink?.href}
      target={sameLevelActiveLink?.target}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        const { id, index, text, href, target } = sameLevelActiveLink || {};

        handleSelectedItem({ id, index, text, href, target });
      }}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation Item">
        <UeElement type="text" property="Text" label="Parent Page Item Link">
          {sameLevelActiveLink.subLevelChildLabel}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  ) : topLevelActiveLink?.href && !isParentItem ? (
    <StyledOverViewLink
      $isSecondaryLevel
      href={topLevelActiveLink?.href}
      target={topLevelActiveLink?.target}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectedItem(topLevelActiveLink);
        if (typeof window !== 'undefined' && topLevelActiveLink?.href) {
          const { target, href } = topLevelActiveLink;
          target === '_blank' ? window.open(href) : (window.location.href = href);
        }
      }}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation Item">
        <UeElement type="text" property="subLevelChildLabel" label="Sub Level Child Label">
          {topLevelActiveLink.subLevelChildLabel || topLevelActiveLink.text}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  ) : (
    <StyledOverViewLabel
      $hasParentElement
      $isSecondaryLevel={isSecondaryLevel}
      className={!isLoadedFromUrl && isParentItem ? 'active-link' : ''}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation item">
        <UeReference propertyPath={propertyPath} label="Label field">
          <UeElement type="text" property="Text" label="Parent Page Item Label">
            {label}
          </UeElement>
        </UeReference>
      </UeContainer>
    </StyledOverViewLabel>
  );
});

const OverViewLabel: React.FC<OverViewLabelProps> = ({ isSecondaryLevel }) => {
  return (
    <StyledListItem
      role="none"
      className="parent-item"
      $isSecondaryLevel={isSecondaryLevel}
      $hasParentElement
      $isMobile
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {isSecondaryLevel ? <SecondLevelLabel isSecondaryLevel={isSecondaryLevel} /> : <Link />}
    </StyledListItem>
  );
};

export default OverViewLabel;
