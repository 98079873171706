/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import NavBarWrapper from './NavBarWrapper';
import NavItems from './NavItems';
import { TrackingServiceContext, useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import { ActiveLinks, MainLink } from '../../../types';
import {
  getItemTitle,
  getTrackingLabelDisplay,
  hasSubLinks,
  isSecondaryLevelFromUrl,
} from '../../utils/utils';
import OverViewLabel from './OverViewLabel';
import { getIndexPosition, useTracking } from '../../hooks/use-tracking';

interface IProps {
  visible: boolean;
}

const SecondLevelNavDesktop = (props: IProps) => {
  const dispatch = useInPageDispatch();
  const { visible } = props;
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);

  const {
    parentPageInpageNavigation,
    sameLevelInpageNavigation,
    topLevelActiveLink,
    sameLevelActiveLink,
    secondLevelActiveLink,
    subNavActive,
  } = useInPageNavContext();
  const indx = topLevelActiveLink ? topLevelActiveLink?.index : sameLevelActiveLink?.index || 0; // subNavActive?.index || 0;

  const isSubLevelUrl = isSecondaryLevelFromUrl(secondLevelActiveLink);

  // eslint-disable-next-line consistent-return
  const subLinks: MainLink[] = useMemo(() => {
    // load sublinks from match url and secondLevelActiveLink context
    if (secondLevelActiveLink && isSubLevelUrl) {
      const i = indx >= 0 ? indx : Number(secondLevelActiveLink.index);
      return hasSubLinks(i, sameLevelInpageNavigation);
    }
    return hasSubLinks(indx, sameLevelInpageNavigation);
  }, [sameLevelInpageNavigation, visible, secondLevelActiveLink, subNavActive, indx]);

  const handleSelectedItem = useCallback(
    ({ id, index, text, href, target }: ActiveLinks) => {
      dispatch?.({
        type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
        payload: {
          secondLevelActiveLink: { id, index, text, href, target, overviewLinkSelected: false },
        },
      });
    },
    [dispatch, secondLevelActiveLink],
  );

  // tracking for toggle subnav menu
  useEffect(() => {
    if (subNavActive?.subLinks?.hasLinks) {
      const parentItem = `${parentPageInpageNavigation?.fields.subLevelParentLabel as string} > `;
      const relatedElements =
        subLinks?.map((item) => {
          return {
            elementName: 'navigation item',
            elementValue: getItemTitle(item.fields),
          };
        }) || [];
      tracking?.toggleNavigation({
        elementName: 'text link',
        label: `${parentItem}${topLevelActiveLink?.subLevelChildLabel || sameLevelActiveLink?.subLevelChildLabel}${getTrackingLabelDisplay(sameLevelActiveLink, topLevelActiveLink)}`,
        clickID: '',
        targetURL: topLevelActiveLink?.href || sameLevelActiveLink?.href || '',
        value: 'same level',
        pos: `${getIndexPosition(topLevelActiveLink?.index || 0)}`,
        eventName: `inpage navigation - ${subNavActive?.subLinks?.active ? 'open' : 'close'} sub navigation`,
        relatedElements,
      });
    }
  }, [subNavActive]);

  // tracking event from second level navigation links, excludes overview links
  useEffect(() => {
    if (secondLevelActiveLink && !secondLevelActiveLink?.overviewLinkSelected) {
      const topLevelItem = topLevelActiveLink || sameLevelActiveLink;
      tracking?.navigationItemClick({
        elementName: 'text link',
        label: `${topLevelItem?.text}${getTrackingLabelDisplay(secondLevelActiveLink, sameLevelActiveLink, topLevelActiveLink)}`,
        clickID: '',
        targetURL: secondLevelActiveLink?.href || '',
        value: `${secondLevelActiveLink?.index} sub level`,
        pos: `${topLevelItem?.index}-${secondLevelActiveLink?.index}`,
        isSubNavigation: true,
        eventName: `inpage navigation - click on link`,
      });
    }
  }, [secondLevelActiveLink]);

  return (
    subLinks?.length > 0 && (
      <NavBarWrapper visible={visible} isSecondaryLevel>
        <OverViewLabel isSecondaryLevel />
        {subLinks?.map((item, i) => {
          const { fields } = item;
          return (
            <NavItems
              // eslint-disable-next-line react/no-array-index-key
              key={`nav-item-${i}`}
              fields={fields}
              index={i}
              hasParentElement={false}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              handleSelectedItem={handleSelectedItem}
              fieldsLength={subLinks?.length}
              isSecondaryLevel
            />
          );
        })}
      </NavBarWrapper>
    )
  );
};

export default SecondLevelNavDesktop;
